import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loader from '../../../Common/Loader';
import { ConvertToForecast, ForecastModel, INITIAL_FORECAST } from './forecast-model';
import ForecastDay from './forecastDay';
import { DivForecastContentStyle, DivForecastStyle, DivForecastTitleStyle } from './styles';

const Forecast: React.FC = () => {

    const [forecast, setForecast] = useState<ForecastModel>(INITIAL_FORECAST);
    
    const [loading, setLoading] = useState(true);

    const forecastData = () => {
        setLoading(true);
        axios.get('https://api.openweathermap.org/data/2.5/onecall?lat=47.080121&lon=-1.580439&units=metric&lang=fr&appid=70ecc0cb8bcbc3ea284d5162def7f39a&exclude=current,minutely,hourly')
            .then((res) => {
                setForecast(ConvertToForecast(res.data));
                setLoading(false);
            })
            .catch((e) => {
                console.log("Forecast component : " + e.message);
                setLoading(false);
            });
    }

    // Rechargement toutes les 10 minutes
    useEffect(() => {
        const timer = setInterval(() => forecastData(), 600000);

        return function cleanup() {
            clearInterval(timer);
        }

    });

    useEffect(() => {
        forecastData();
    }, []);

    return (
        <>
            {loading && <Loader />}
            
            {!loading &&
                <>
                    <DivForecastStyle>
                        <DivForecastTitleStyle>Prévisions à 5 jours</DivForecastTitleStyle>
                        <DivForecastContentStyle>
                            <ForecastDay forecastDayInfo={forecast.forecastDayOne}/>
                            <ForecastDay forecastDayInfo={forecast.forecastDayTwo}/>
                            <ForecastDay forecastDayInfo={forecast.forecastDayThree}/>
                            <ForecastDay forecastDayInfo={forecast.forecastDayFour}/>
                            <ForecastDay forecastDayInfo={forecast.forecastDayFive}/>
                        </DivForecastContentStyle>
                    </DivForecastStyle>
                </>
            }
        </>
    );
  };
  
  export default Forecast;

