import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { faker } from '@faker-js/faker';
import { ForecastTableStyle, TD6Style, TDStyle, TDValue3Style, TDValueStyle } from './styles';
import { ConvertToForecastDayCompleteInfo, ConvertToKmHeure, ForecastDayCompleteInfoModel, FormatHeure, INITIAL_FORECAST_DAY_COMPLETE_INFO, MoonPhase, WindDirection } from './forecast-model';
import Loader from '../../../Common/Loader';

const ForecastDayCompleteInfoView = () => {

    const { dt } = useParams();

    const [loading, setLoading] = useState(true);
    const [forecastDayCompleteInfo, setForecastDayCompleteInfo] = useState<ForecastDayCompleteInfoModel>(INITIAL_FORECAST_DAY_COMPLETE_INFO);

    const ForecastDayCompleteInfoData = () => {
        setLoading(true);

        axios.get('https://api.openweathermap.org/data/2.5/onecall?lat=47.080121&lon=-1.580439&units=metric&lang=fr&appid=70ecc0cb8bcbc3ea284d5162def7f39a&exclude=current,minutely,hourly')
            .then((res) => {
                const obj = res.data;
                const cnt = obj.daily.length;
                const dateSelect = new Date(Number(dt) * 1000);

                // On ne récupère que le jour cliqué
                for (let index = 0; index < cnt; index++) {
                    const dayData = obj.daily[index];
                    const dt = new Date(dayData.dt * 1000);
                    if(dt.getDate() === dateSelect.getDate()) {
                        setForecastDayCompleteInfo(ConvertToForecastDayCompleteInfo(dayData));
                        break;
                    }
                }

                setLoading(false);
            })
            .catch((e) => {
                console.log("ForecastDayCompleteInfo component : " + e.message);
                console.log(e.response.data.message);

                let info = {
                    sunrise: FormatHeure(new Date()),
                    sunset: FormatHeure(new Date()),
                    moonrise: FormatHeure(new Date()),
                    moonset: FormatHeure(new Date()),
                    moonphase: MoonPhase(faker.datatype.float({ min: 0, max: 1 })),
                    tempmorn: Math.round(faker.datatype.float({ min: 0, max: 10 })) + " °C",
                    tempday: Math.round(faker.datatype.float({ min: 10, max: 20 })) + " °C",
                    tempeve: Math.round(faker.datatype.float({ min: 0, max: 10 })) + " °C",
                    tempnight: Math.round(faker.datatype.float({ min: -5, max: 10 })) + " °C",
                    tempmin: Math.round(faker.datatype.float({ min: -5, max: 10 })) + " °C",
                    tempmax: Math.round(faker.datatype.float({ min: 10, max: 20 })) + " °C",
                    feelslikemorn: Math.round(faker.datatype.float({ min: 0, max: 10 })) + " °C",
                    feelslikeday: Math.round(faker.datatype.float({ min: 10, max: 20 })) + " °C",
                    feelslikeeve: Math.round(faker.datatype.float({ min: 0, max: 10 })) + " °C",
                    feelslikenight: Math.round(faker.datatype.float({ min: -5, max: 10 })) + " °C",
                    pressure: faker.datatype.float({ min: 990, max: 1030 }) + " hPa",
                    humidity: faker.datatype.float({ min: 0, max: 100 }) + " %",
                    dewpoint: Math.round(faker.datatype.float({ min: 0, max: 10 })) + " °C",
                    windspeed: ConvertToKmHeure(faker.datatype.float({ min: 0, max: 5 })) + " Km/h",
                    windgust: ConvertToKmHeure(faker.datatype.float({ min: 5, max: 10 })) + " Km/h",
                    winddeg: WindDirection(faker.datatype.float({ min: 0, max: 360 })),
                    pop: Math.round(100 * faker.datatype.float({ min: 0, max: 1 })) + " %",
                    rain: Math.round(faker.datatype.float({ min: 0, max: 10 })) + " mm"
                };

                setForecastDayCompleteInfo(ConvertToForecastDayCompleteInfo(info));
                setLoading(false);
            });
    }

    useEffect(() => {
        ForecastDayCompleteInfoData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {loading && <Loader />}

            {!loading &&
            <ForecastTableStyle>
                <tbody>
                    <tr>
                        <TDStyle>Lever du soleil : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.sunrise}</TDValueStyle>
                        <TDStyle>Coucher du soleil : </TDStyle><TDValue3Style>{forecastDayCompleteInfo.sunset}</TDValue3Style>
                    </tr>
                    <tr><TD6Style>&nbsp;</TD6Style></tr>
                    <tr>
                        <TDStyle>Lever de lune : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.moonrise}</TDValueStyle>
                        <TDStyle>Coucher de lune : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.moonset}</TDValueStyle>
                        <TDStyle>Phase : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.moonphase}</TDValueStyle>
                    </tr>
                    <tr><TD6Style>&nbsp;</TD6Style></tr>
                    <tr>
                        <TDStyle>Température du matin : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.tempmorn}</TDValueStyle>
                        <TDStyle>Température ressentie : </TDStyle><TDValue3Style>{forecastDayCompleteInfo.feelslikemorn}</TDValue3Style>
                    </tr>
                    <tr>
                        <TDStyle>Température du jour : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.tempday}</TDValueStyle>
                        <TDStyle>Température ressentie : </TDStyle><TDValue3Style>{forecastDayCompleteInfo.feelslikeday}</TDValue3Style>
                    </tr>
                    <tr>
                        <TDStyle>Température du soir : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.tempeve}</TDValueStyle>
                        <TDStyle>Température ressentie : </TDStyle><TDValue3Style>{forecastDayCompleteInfo.feelslikeeve}</TDValue3Style>
                    </tr>
                    <tr>
                        <TDStyle>Température de la nuit : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.tempnight}</TDValueStyle>
                        <TDStyle>Température ressentie : </TDStyle><TDValue3Style>{forecastDayCompleteInfo.feelslikenight}</TDValue3Style>
                    </tr>
                    <tr><TD6Style>&nbsp;</TD6Style></tr>
                    <tr>
                        <TDStyle>Température min. : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.tempmin}</TDValueStyle>
                        <TDStyle>Température max. : </TDStyle><TDValue3Style>{forecastDayCompleteInfo.tempmax}</TDValue3Style>
                    </tr>
                    <tr><TD6Style>&nbsp;</TD6Style></tr>
                    <tr>
                        <TDStyle>Pression atmosphérique : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.pressure}</TDValueStyle>
                        <TDStyle>Humidité : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.humidity}</TDValueStyle>
                        <TDStyle>Point de rosée : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.dewpoint}</TDValueStyle>
                    </tr>
                    <tr><TD6Style>&nbsp;</TD6Style></tr>
                    <tr>
                        <TDStyle>Vitesse du vent : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.windspeed}</TDValueStyle>
                        <TDStyle>Rafale : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.windgust}</TDValueStyle>
                        <TDStyle>Direction du vent : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.winddeg}</TDValueStyle>
                    </tr>
                    <tr><TD6Style>&nbsp;</TD6Style></tr>
                    <tr>
                        <TDStyle>Probabilité de précipitation : </TDStyle><TDValueStyle>{forecastDayCompleteInfo.pop}</TDValueStyle>
                        <TDStyle>Volume de précipitation : </TDStyle><TDValue3Style>{forecastDayCompleteInfo.rain}</TDValue3Style>
                    </tr>
                </tbody>
            </ForecastTableStyle>

            }
        </>
    );
};

export default ForecastDayCompleteInfoView;

